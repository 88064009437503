import * as React from "react";
import { observer } from "mobx-react";
import { TablePriceTrendProps } from "../../typings";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";
import { EGDSTable, EGDSTableHead, EGDSTableBody, EGDSTableRow, EGDSTableCell } from "@egds/react-core/tables";

const TablePriceTrend = observer((props: TablePriceTrendProps) => {
  const { headerRow, priceTrend, titleDetailsA11y } = props;

  if (!headerRow || !priceTrend) {
    return null;
  }

  return (
    <EGDSSpacing padding={{ block: "three", inlinestart: "three" }}>
      <div>
        <EGDSTable summary={titleDetailsA11y}>
          <EGDSTableHead>
            <EGDSTableRow>
              {headerRow?.map((it, index) => (
                <EGDSSpacing padding="two" key={`table-price-trend-0-header-${index}`}>
                  <EGDSTableCell>
                    <EGDSText weight="bold" size={400} align={index === headerRow.length - 1 ? "right" : undefined}>
                      {it}
                    </EGDSText>
                  </EGDSTableCell>
                </EGDSSpacing>
              ))}
            </EGDSTableRow>
          </EGDSTableHead>
          <EGDSTableBody>
            {priceTrend?.map((row, index) => (
              <EGDSTableRow key={index}>
                <EGDSSpacing padding="two">
                  <EGDSTableCell>
                    <EGDSText weight="medium" size={300}>
                      {row.label}
                    </EGDSText>
                  </EGDSTableCell>
                </EGDSSpacing>
                <EGDSSpacing padding="two">
                  <EGDSTableCell>
                    <EGDSText weight="medium" size={300} align="right">
                      {row.displayPrice}
                    </EGDSText>
                  </EGDSTableCell>
                </EGDSSpacing>
              </EGDSTableRow>
            ))}
          </EGDSTableBody>
        </EGDSTable>
      </div>
    </EGDSSpacing>
  );
});

export default TablePriceTrend;

import * as React from "react";
import { observer } from "mobx-react";
import { DisclaimerProps } from "../typings";
import { EGDSText } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";

const Disclaimer = observer((props: DisclaimerProps) => {
  const { disclaimer } = props;

  return (
    <EGDSSpacing padding={{ block: "six" }}>
      <EGDSText size={200} align="center" weight="regular">
        {disclaimer}
      </EGDSText>
    </EGDSSpacing>
  );
});

export default Disclaimer;

import * as React from "react";
import { observer } from "mobx-react";
import { DialogProps, TablePriceTrendProps } from "../../typings";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSSheet, EGDSSheetContent, EGDSSheetTransition } from "@egds/react-core/sheet";
import { EGDSToolbar, EGDSToolbarProps } from "@egds/react-core/toolbar";
import { EGDSSpacing } from "@egds/react-core/spacing";
import TablePriceTrend from "../TablePriceTrend/TablePriceTrend";
import Disclaimer from "../Disclaimer";
import { useDialog } from "@shared-ui/dialog-context";

type DialogExtendedProps = DialogProps & TablePriceTrendProps;

const TablePriceTrendDialog = observer((props: DialogExtendedProps) => {
  const { title, iconLabel, headerRow, priceTrend, linkText, linkA11yLabel, disclaimer, titleDetailsA11y } = props;

  if (!headerRow || !priceTrend || !linkText) {
    return null;
  }

  const [isDialogOpen, dialogActions, DialogComponent] = useDialog("TablePriceTrend");
  const triggerRef = React.createRef<HTMLElement>();

  const toolbarProps: EGDSToolbarProps = {
    toolbarTitle: title,
    navigationContent: {
      navIconLabel: iconLabel,
      onClick: dialogActions.closeDialog as any,
    },
  };

  const tableProps: TablePriceTrendProps = {
    headerRow,
    priceTrend,
    titleDetailsA11y,
  };

  return (
    <>
      <EGDSSpacing padding={{ blockstart: "three", blockend: "three" }} border={["blockstart"]}>
        <EGDSLink inline onClick={dialogActions.openDialog as any}>
          <button>
            <span aria-hidden>{linkText}</span>
            <span className="is-visually-hidden">{linkA11yLabel}</span>
          </button>
        </EGDSLink>
      </EGDSSpacing>
      <EGDSSheetTransition isVisible={isDialogOpen}>
        <DialogComponent>
          <EGDSSheet type="full" triggerRef={triggerRef} isVisible={isDialogOpen}>
            <EGDSToolbar {...toolbarProps} />
            <EGDSSheetContent>
              <TablePriceTrend {...tableProps} />
              <Disclaimer disclaimer={disclaimer} />
            </EGDSSheetContent>
          </EGDSSheet>
        </DialogComponent>
      </EGDSSheetTransition>
    </>
  );
});

export default TablePriceTrendDialog;

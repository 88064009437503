import * as React from "react";
import { observer } from "mobx-react";
import { HighlightsProps } from "../../typings";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";
import { EGDSTable, EGDSTableHead, EGDSTableBody, EGDSTableRow, EGDSTableCell } from "@egds/react-core/tables";

const Highlights = observer((props: HighlightsProps) => {
  const { highlights, titleSummaryA11y } = props;

  /* istanbul ignore next */
  if (!highlights) {
    return null;
  }

  const spacingProps = (index: number): EGDSSpacingProps => {
    const lastIndex = highlights.length - 1;

    if (index === 0) {
      return {
        padding: {
          medium: { inlineend: "twelve" },
          small: { inlineend: "three" },
        },
      };
    }

    if (index === lastIndex) {
      return {
        padding: {
          medium: { inlinestart: "twelve" },
          small: { inlinestart: "three" },
        },
      };
    }

    return {
      padding: {
        medium: { inlinestart: "twelve", inlineend: "twelve" },
        small: { inlinestart: "three", inlineend: "three" },
      },
    };
  };

  return (
    <EGDSSpacing padding={{ block: "four" }}>
      <div id="highlights">
        <EGDSTable summary={titleSummaryA11y}>
          <EGDSTableHead>
            <EGDSTableRow>
              {highlights.map((it, index) => (
                <EGDSSpacing key={`highlights_0_title_${index}`} padding="unset">
                  <EGDSTableCell>
                    <EGDSSpacing {...spacingProps(index)}>
                      <EGDSText weight="regular" theme="emphasis" whiteSpace="nowrap">
                        {it.title}
                      </EGDSText>
                    </EGDSSpacing>
                  </EGDSTableCell>
                </EGDSSpacing>
              ))}
            </EGDSTableRow>
          </EGDSTableHead>
          <EGDSTableBody>
            <EGDSTableRow>
              {highlights.map((it, index) => (
                <EGDSSpacing key={`highlights_0_content_${index}`} padding="unset">
                  <EGDSTableCell>
                    <EGDSSpacing {...spacingProps(index)}>
                      <EGDSText weight="bold" size={500}>
                        {it.content}
                      </EGDSText>
                    </EGDSSpacing>
                  </EGDSTableCell>
                </EGDSSpacing>
              ))}
            </EGDSTableRow>
          </EGDSTableBody>
        </EGDSTable>
      </div>
    </EGDSSpacing>
  );
});

export default Highlights;
